import moment from "moment";

export function formatDate(_date, time = true) {
  if(_date){
    let newDate = _date.replace('T','-').replace('.000Z','');
    let date = newDate.split('-')

    if(time === false){
      return moment(date[0]+'-'+date[1]+'-'+date[2]).format('DD MMM YY')
    }

    return date[2]+'-'+date[1]+'-'+date[0]+' '+date[3];
  }
}

export function formatDay(_date, time = true) {
  if(_date){
    let newDate = _date.replace('T','-').replace('.000Z','');
    let date = newDate.split('-')

   

    let day = moment(date[0]+'-'+date[1]+'-'+date[2]).format('DD MMMM YYYY')

    if(time === false){
      return day;
    }

    return day+' '+date[3];
  }
}

export function addCommas(x) {
  if(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}